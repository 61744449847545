import React, { TextareaHTMLAttributes } from "react"
import { useField } from "formik"
import classNames from "classnames"
import { Variant } from "./helpers"
import { Button } from "react-bootstrap"

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string
  name: string
  variant?: Variant
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({ label, variant, id, name, ...props }, ref) => {
  const [field, meta] = useField(name)

  const isFloating = variant == "floating"

  const htmlFor = id ?? field.name

  const labelComp = <label htmlFor={htmlFor}>{label}</label>
  const inputComp = (
    <textarea
      className={classNames("form-control", {
        "is-invalid": meta.touched && meta.error,
      })}
      ref={ref}
      {...field}
      {...props}
      style={{ height: "150px" }}
    ></textarea>
  )

  return (
    <div className={classNames({ "form-floating": isFloating })} {...props}>
      {isFloating ? (
        <>
          {inputComp}
          {labelComp}
        </>
      ) : (
        <>
          {labelComp}
          {inputComp}
        </>
      )}
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  )
})

/** export */
export default TextArea
