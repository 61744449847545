import React, { useEffect, useState } from "react"
import { Formik, Form, FormikHelpers, useFormikContext } from "formik"
import Select from "../form/Select"
import TextArea from "../form/TextArea"
import Input from "../form/Input"
import Checkbox from "../form/Checkbox"
import axios from "axios"
import * as yup from "yup"
import NorhartBlackCircleIcon from "../../../assets/norhart-black-circle-icon.svg"

/** components */
import { ColorPalette } from "../Theme"

export interface ContactSubmission {
  name: string
  phone: string | undefined
  email: string | undefined
  message: string | undefined
}

const initialValues = {
  name: "",
  phone: "",
  email: "",
  message: "",
}

const ContactSchema = yup.object().shape({
  name: yup.string().required("Required"),
  phone: yup
    .string()
    .transform(function (value, originalValue) {
      return this.isType(value) && (value === null || value == "") ? null : value
    })
    .nullable(true)
    .matches(/\+?(\d+)?[ \.-]?\(?(\d{3})\)?[ \.-]?(\d{3})[ \.-]?(\d{4})/, "Invalid Phone Number"),
  email: yup.string().email("Invalid Email").nullable(true),
  message: yup.string(),
})

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const SubmitFundContactSection: React.FC<Props> = (props) => {
  const successRef = React.useRef<HTMLInputElement>(null)
  const [didSubmit, setDidSubmit] = useState(false)

  const setDidSubmitHandler = (v: boolean) => {
    setDidSubmit(v)
    if (v) {
      successRef.current.scrollIntoView()
    }
  }

  return (
    <div>
      {didSubmit ? (
        <div ref={successRef}>
          <Success {...props} />
        </div>
      ) : (
        <ContactForm {...props} setDidSubmitHandler={setDidSubmitHandler} />
      )}
    </div>
  )
}
/** export */
export default SubmitFundContactSection

interface ContactFormProps extends Props {
  setDidSubmitHandler: (v: boolean) => void
}

const ContactForm: React.FC<ContactFormProps> = (props) => {
  const { colorPalette, setDidSubmitHandler } = props

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ContactSchema}
      onSubmit={async (values: ContactSubmission, { setSubmitting }: FormikHelpers<ContactSubmission>) => {
        let filteredValues = Object.fromEntries(
          Object.entries(ContactSchema.cast(values)).filter(([_, v]) => v != null && v != "")
        )

        await axios.post(process.env.GATSBY_FUND_CONTACT_URL, filteredValues)
        setSubmitting(false)
        setDidSubmitHandler(true)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <section
            className="container-fluid sectionBaseColor pt-5 pb-5"
            style={{
              color: `${colorPalette.sectionText}`,
              background: `linear-gradient(#f0f0f0 30%, #f0f0f0 70%)`,
            }}
          >
            <div className="container pl-3 pr-3 text-center">
              <div className="container pb-5"></div>
              <div className="text-center pb-2">
                <NorhartBlackCircleIcon />
              </div>

              <div className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl" style={{ fontSize: "15px" }}>
                NORHART
              </div>

              <hr
                className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mb-3 mt-3"
                style={{
                  borderColor: "rgba(0, 0, 0, 0.2)",
                }}
              />

              <h2
                className="contentHeroTitle"
                style={{
                  color: "#111111",
                }}
              >
                Contact Us
              </h2>

              <h4 className="contentHeroSubTitle" style={{ color: "#111111", opacity: "0.8" }}>
                Do you have a question about The Norhart Fund? Please fill out the form below and our team will get back
                to you as soon as possible.
              </h4>
            </div>

            <div className="container pl-3 pr-3 mt-5 text-left">
              <div className="mb-3 mt-3">
                <Input variant="floating" name="name" label="Name" placeholder="John Appleseed" />
              </div>

              <div className="mb-3 mt-3">
                <Input variant="floating" name="phone" type="tel" label="Phone Number" placeholder="(555) 555-1234" />
              </div>

              <div className="mb-3 mt-3">
                <Input
                  variant="floating"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="john.appleseed@norhart.com"
                />
              </div>

              <div className="mb-3 mt-3">
                <TextArea variant="floating" name="message" label="Message" />
              </div>

              <FormErrorMessage />

              <button
                type="submit"
                className="btn btn-md btn-light animate__animated animate__pulse animate__infinite animate__slow mb-5"
                style={{ backgroundColor: "#21a7ff", border: "0px", color: "#ffffff" }}
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                )}
                {isSubmitting ? " Submitting" : "Send"}
              </button>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  )
}

const FormErrorMessage: React.FC = () => {
  const { errors, touched } = useFormikContext()
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    for (const property in errors) {
      if (touched[property]) {
        setShowError(true)
        return
      }
    }
    setShowError(false)
  }, [errors, touched])

  if (showError) {
    return (
      <div className="alert alert-danger" role="alert">
        Oh no, there are some errors above!
      </div>
    )
  } else {
    return null
  }
}

interface SuccessProps extends Props {}

const Success: React.FC<SuccessProps> = (props) => {
  return (
    <section
      className="container-fluid sectionBaseColor pt-5 pb-5"
      style={{
        color: `${props.colorPalette.sectionText}`,
      }}
    >
      <div className="container pl-3 pr-3 text-center">
        <div className="container pb-5"></div>
        <h2
          className="contentHeroTitle"
          style={{
            color: "#365c70",
          }}
        >
          <img src="/feedback/thanks-for-sharing.png" className="mb-1" />
          <br />
          Thanks For Sharing!
        </h2>

        <h4 className="contentHeroSubTitle mb-5" style={{ color: "#365c70", opacity: "0.8" }}>
          Thanks again for contacting us we wil be in touch soon!
        </h4>
      </div>
    </section>
  )
}
