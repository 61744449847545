import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../../components/LayoutFund"
import { theme } from "../../components/Theme"

/** static sections */
import SubmitFundContactSection from "../../components/static-sections/SubmitFundContactSection"

/** dynamic sections */
import HeroInvestImage from "../../components/dynamic-sections/HeroInvestImage"
import HeroVideoFund from "../../components/dynamic-sections/HeroVideoFund"
import HeroInvestImageHero from "../../components/dynamic-sections/HeroInvestImageHero"
import FundRibbon from "../../components/dynamic-sections/FundRibbon"
import HeroBottomIFundTextSection from "../../components/dynamic-sections/HeroBottomIFundTextSection"
import FundServiceStats from "../../components/dynamic-sections/FundServiceStats"
import GoogleMapFundSection from "../../components/dynamic-sections/GoogleMapFundSection"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import NorhartBlackCircleIcon from "../../../assets/norhart-black-circle-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.black

/** props */
interface Props {
  data?: any
  didSubmit: boolean
}

/** const */
export const fundContactSection: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/fund/business-services/investment-management",
          name: "Contact| Norhart Fund",
          image: `${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Contact| Norhart Fund"
      description=""
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroInvestImageHero imageHero="/fund/oakdale/norhart-oakdale-pool-justin-bartak-design.png" />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <SubmitFundContactSection colorPalette={colorPalette} />

      <GoogleMapFundSection
        colorPalette={colorPalette}
        icon={NorhartBlackCircleIcon}
        iconTitle="Norhart HQ"
        query="Forest Lake,+MN+Norhart"
        subtitle="Corporate Headquarters in Forest Lake, MN."
        title="Norhart Fund"
      />
    </Layout>
  )
}

/** export */
export default fundContactSection
